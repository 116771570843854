import React from 'react';
import './ServiceCard.css'; // Import the CSS for styling

const ServiceCard = ({ title, icon }) => {
  return (
    <div className="service-card">
      <div className="service-content">
        <h3>{title}</h3>
      </div>
      <div className="service-icon">
        <img src={icon} alt={`${title} icon`} />
      </div>
    </div>
  );
};

export default ServiceCard;
