import React from 'react';
import './PricingComponent.css'; // Import the CSS file for styling

const PricingComponent = () => {
    return (
       <>
      <h3 data-aos="fade" data-aos-duration="700" data-aos-once="True" data-aos-delay="50" class="aos-init aos-animate" style={{marginTop:"2em" , marginBottom:"1em"}}><b>PRICING</b></h3>
        <div  className="pricing-container" id ="pricing">
            
            <div className="pricing-card">
                <h4 className="plan-title">THE FORCE</h4>
                <p className="starting-price">Starting at $225 per month for Cash basis of accounting</p>
                <p className="starting-price">Starting at $540 per month for Accrual basis of accounting</p>
                <ul>
                    <li>💰 Small business (up to $2.0 million annual revenue with few customers and suppliers)</li>
                    <li>💼 Dedicated professional</li>
                    <li>📊 Daily bookkeeping including AP and AR cycles</li>
                    <li>🔄 Reconciliations - AP, AR and Bank</li>
                    <li>📅 Month-end and year-end closing</li>
                </ul>
                
            </div>
            
            <div className="pricing-card mid-card">
                <h4 className="plan-title">THE GAMECHANGER</h4>
                <p className="starting-price">Starting at $750 per month for Cash basis of accounting</p>
                <p className="starting-price">Starting at $1,350 per month for Accrual basis of accounting</p>
                <ul>
                    <li>📈 Growing business (up to $4.0 million annual revenue with growing list of customers and/or suppliers)</li>
                    <li>👨‍💼 Dedicated Controller</li>
                    <li>📋 Budget preparation and variance analysis</li>
                    <li>📊 Daily bookkeeping including AP and AR cycles</li>
                    <li>🔄 Reconciliations - AP, AR and Bank</li>
                    <li>📅 Month-end and year-end closing</li>
                    <li>📑 Customized reports</li>
                </ul>
               
            </div>
            
            <div className="pricing-card">
                <h4 className="plan-title">THE TRAILBLAZER</h4>
                <p className="starting-price">Starting at $3,500 per month for:</p>
                <ul>
                    <li>🏢 Mid-size to large businesses (above $4 million annual revenue)</li>
                    <li>👔 CFO role</li>
                    <li>📊 Controller role</li>
                </ul>
                
            </div>
        </div>
       
       
       </>
    );
};

export default PricingComponent;
