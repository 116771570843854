import React from 'react';
import './CountryServiceCard.css'; // Ensure this import is present
import { Link } from 'react-router-dom';
import buttonIcon from "../../../assets/Fractional/arrow.svg"


const CountryServiceCard = ({ title, icon, route }) => {
  return (
    <div className="country-service-card">
      <div className="country-service-icon">
        <img src={icon} alt={title} />
      </div>
      <h3>{title}</h3>
      {route && ( // Only render the Next button if the route is provided
        <Link to={route} className="next-button">
          <img className="button-icon" src={buttonIcon} alt="button"/>
        </Link>
      )}
    </div>
  );
};

export default CountryServiceCard;
