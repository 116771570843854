import React from "react";
import CanadaServices from "../Canada/Services"; // Canada-specific services
import Cokies from "../../Cokies";
import Hero from "../../Hero";
import Navbar from "../../Navbar";
import NavbarTest from "../../NavbarTest";
import SecondLast from "../../SecondLast";
import Testimonials from "../../Testimonials";
import Train from "../../Train";
import ContactUs from "../../ContactUs";
import PricingComponent from "../../Pricing/PricingComponent";
import Footer from "../../Footer";
import Team from "../../Team/Team";

const Canada = () => {
  return (
    <>
      <Cokies />
      <Hero />
      <Navbar />
      <NavbarTest />
      <CanadaServices /> {/* Canada-specific service component */}
      <SecondLast />
      <Testimonials />
      <Team />
      <Train />
      <PricingComponent />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Canada;
