import React from 'react'
import {BrowserRouter, Route, Routes, Link} from 'react-router-dom'

import CountriesNav from '../../countries/CountriesNav'
import ServiceCard from "../../SeviceCard.js"
import dealTeamIcon from "../../../assets/logo192_T.png"
import AboutUs from '../../AboutUs.js'


const Services = () => {
  return (
  <>
    <div style={{paddingTop: '1em', paddingBottom: '1em', backgroundColor: '#fafcfa'}} id='about1'></div>
    <div></div>
    <div className='mountbg'>
      <AboutUs/>
      <section className='sec2' id='services1'>
        <div className='linesmall'></div>
        <div className='shapecurve'></div>
        <div className='mtbody'>
          <div className='container' id='services'>
            <div ></div>
            
            <div style={{paddingTop: '2em', paddingBottom: '4em'}} id='services1'> 
             
            </div>
            <h3 style={{paddingBottom: '3em',fontWeight: '600'}} data-aos='fade' data-aos-duration='1000' data-aos-once='True' data-aos-delay='100'><b>Services</b></h3>
            <CountriesNav/>
            <div className='newgrid' style={{paddingTop: '1em'}}>
                <Link  to='/australia/service1'><div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="20"><ServiceCard title="General Accounting" icon={dealTeamIcon} /></div></Link>
             
                <Link  to='/australia/service2'><div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="20"><ServiceCard title="Specialist Services " icon={dealTeamIcon} /></div></Link>
                <Link  to='/australia/service3'><div className='imgcenter' style={{left:"50%",position:"relative", zIndex:"10"}} data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="30"><ServiceCard title="Project Services" icon={dealTeamIcon} /></div></Link>
                
                
                
                
            </div>

            {/* responsive */}
            <div className='newgrid_ipad' style={{paddingTop: '1em'}}>
                <Link to='/australia/service1'><div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="20"><ServiceCard title="General Accounting" icon={dealTeamIcon} /></div></Link>
                <Link  to='/australia/service2'><div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="20"><ServiceCard title="Specialist Services" icon={dealTeamIcon} /></div></Link>
                <Link  to='/australia/service3'><div className='imgcenter' data-aos='fade-up' data-aos-duration="350" data-aos-once="True" data-aos-delay="30"><ServiceCard title="Project Service" icon={dealTeamIcon} /></div> </Link> 
                              
                
                

               
            </div>
            
            
          </div>
          <div className='line4'></div>
        </div>
        <div className='traiangleMid'></div>
        <svg className='cornerimg' viewBox='0 0 500 500' preserveAspectRatio='none'>
          <mask id='svgmask1'>
            <polygon points='0,250 500,0 500,500' fill='#ffffff'></polygon>
          </mask>
          <image xlinkHref='imgicon/people_new.jpg' mask='url(#svgmask1)' width='200%' height='300%' y='-120%' x='-60%' preserveAspectRatio='none'></image>
        </svg>
        <div className='bottomtraingle'></div>
      </section>
      
      <div className='mountbgimg'>
        <span className='mountimage'>
          <img alt='mount' style={{opacity: '.9'}} sizes='100vw' src='imgicon/mount_green.png' decoding='async'></img> 
        </span>
      </div>
    </div>
   
   </>
  )
}

export default Services
