import React from 'react';
import './AssuranceSupportServices.css';

const AssuranceSupportServices = () => {
  const services = [
    'Preparation of regulatory reports and filings',
    'Agreed-upon procedures',
    'Due diligence services',
    'Financial forecasts, budgeting, and projections',
    'Risk assessment and internal control process design',
    'Outsourced internal audit functions',
  ];

  return (
    <div className="assuranceSupportServices-container">
      
       
      <div className="flow-chart assuranceSupportServices-card-heading " data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" style={{ padding: '50px', color: '#34a853', textTransform: 'none', fontSize:'2.0em', lineHeight:"1.5" }}>
       
                  <b>ALPHAXN offers a full array of customizable services to fit your
          specific needs based on size and complexity of your business operations.
          Our customizable support services include:</b>
       
        <p className='flow-chart-para' style={{  color: "black", fontSize:'1.0em' }}> Our customizable support services include:</p>
   </div>
       
     

      <div className="assuranceSupportServices-card-list">
      
        {services.map((service, index) => (
          <div key={index} className="assuranceSupportServices-card">
            <div className="assuranceSupportServices-info">
              <h3>{service}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssuranceSupportServices;
