import React from "react";
import "./Fractional.css";
import NavbarS from "../../../NavbarS";
import Footer from "../../../Footer";
import NavbarTest from "../../../NavbarTest";
import ScrollToTop from "../../../ScrollToTop";

import scalabilityIcon from "../../../../assets/Fractional/scale.jpg";
import costIcon from "../../../../assets/Fractional/cost.jpg";
import BusinessIcon from "../../../../assets/Fractional/Business.jpg";
import expertiseIcon from "../../../../assets/Fractional/expertise.svg";
import objectiveIcon from "../../../../assets/Fractional/objective.svg";
import GuidenceIcon from "../../../../assets/Fractional/Stategic.jpg";

// Import the FractionalCard component
import FractionalCard from "./FractionalCard";

const Fractional = () => {
  const cardData = [
    {
      imgSrc: GuidenceIcon,
      title: "Strategic Guidance",
      content:
        "Fractional CFOs are often seasoned professionals with experience across various industries. They can provide invaluable strategic financial advice on everything from budgeting and forecasting to mergers and acquisitions. This expertise can be instrumental in helping your business make sound financial decisions and achieve its goals.",
    },
    {
      imgSrc: scalabilityIcon,
      title: "Flexibility and Scalability",
      content:
        "The needs of a growing business can fluctuate. A fractional CFO allows you to tailor the level of financial guidance you receive to your current needs. You can increase or decrease their involvement as your business grows or faces specific challenges.",
    },
    {
      imgSrc: costIcon,
      title: "Cost-Effectiveness",
      content:
        "A full-time CFO commands a high salary and benefits package. A fractional CFO allows you to access their expertise for a fraction of the cost, typically on a retainer basis or per-project basis. This makes it much more affordable for smaller companies that wouldn't otherwise be able to justify the expense.",
    },
    {
      imgSrc: BusinessIcon,
      title: "Focus on Core Business",
      content:
        "Entrepreneurs and business owners often wear many hats. A fractional CFO takes the burden of financial management off their shoulders, allowing them to focus on core business activities like sales, marketing, and product development.",
    },
    {
      imgSrc: expertiseIcon,
      title: "Specialized Expertise",
      content:
        "Many fractional CFOs have specialized knowledge in specific areas like venture capital, turnarounds, or specific industries. You can find a fractional CFO whose expertise aligns perfectly with your company's current needs.",
    },
    {
      imgSrc: objectiveIcon,
      title: "Objective Viewpoint",
      content:
        "Unlike someone with a vested interest in the company, a fractional CFO can offer an objective and unbiased perspective on your financial situation. This can be crucial for making sound financial decisions.",
    },
  ];

  return (
    <>
      {/* <NavbarS />
      <NavbarTest /> */}
      <div className="fractional-container">
        <div className="fractional-line"></div>
        <div className="fractional-heading-container">
          <div className="fractional-main-heading">
            <h3 style={{ paddingBottom: "0", fontWeight: "600" }}>
              <b>Fractional CFO Service</b>
            </h3>
          </div>
          <div className="fractional-main-content">
            <p>
              Growing your business requires financial smarts, but hiring a
              full-time CFO can break the bank. ALPHAXN offers fractional CFO
              service in multiple industries. Get expert guidance you can
              afford, on your terms so that you can focus on your business and
              grow your organization.
            </p>
          </div>
        </div>
        <div className="fractional-line"></div>
      </div>
      <div className="fractional-card-container">
        <p className="fractional-card-main-heading">
          Businesses, particularly growing organizations, small businesses and
          startups, benefit from a fractional CFO for several key reasons -{" "}
        </p>
        {cardData.map((card, index) => (
          <FractionalCard
            key={index}
            imgSrc={card.imgSrc}
            title={card.title}
            content={card.content}
          />
        ))}
      </div>
      <div className="fractional-benefits-container">
          <div className="fractional-main-content">
            <p>
              Growing your business requires financial smarts, but hiring a
              full-time CFO can break the bank. ALPHAXN offers fractional CFO
              service in multiple industries. Get expert guidance you can
              afford, on your terms so that you can focus on your business and
              grow your organization.
            </p>
          </div>
          <div className="fractional-benefits-card">
            


          </div>

              
      </div>

      <ScrollToTop />
      <Footer />
    </>
  );
};

export default Fractional;
