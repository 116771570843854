import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/logo_final.png";
import "./Navbar.css";
// import { Link } from "react-router-dom";
import classNames from "classnames";
import useSticky from "./useSticky";
import { hover } from "@testing-library/user-event/dist/hover";
import { color, margin } from "@mui/system";
import { HashLink as Link } from "react-router-hash-link";

const NavbarS = () => {
  const { sticky, stickyRef } = useSticky();

  return (
    <div
      className={classNames("navbar", { sticky })}
      id="navbar"
      ref={stickyRef}
    >
      <div className="logo">
        <a href="/">
          <img src={Logo} alt="logo" />
        </a>
      </div>
      <ul className="navm">
        {/* <li style={{cursor: 'pointer', position: 'relative', letterSpacing: '.15em', color: '#fff'}} className='navi'><Link className='a' to='/'>HOME</Link></li> */}
        <li
          style={{
            cursor: "pointer",
            position: "relative",
            letterSpacing: ".15em",
            color: "#fff",
          }}
          className="navi"
        >
          <Link className="a" to="/#about">
            <b>ABOUT US</b>
          </Link>
        </li>
        <li
          style={{
            cursor: "pointer",
            position: "relative",
            letterSpacing: ".15em",
            color: "#fff",
          }}
          className="navi"
        >
          <Link className="a" to="/#services">
            <b>SERVICES</b>
          </Link>
          <div className="under"></div>
        </li>
        <li
          style={{
            cursor: "pointer",
            position: "relative",
            letterSpacing: ".15em",
            color: "#fff",
          }}
          className="navi"
        >
          <Link className="a" to="/#industries">
            <b>CLIENTS WE SERVE</b>
          </Link>
        </li>
        <li
          style={{
            cursor: "pointer",
            position: "relative",
            letterSpacing: ".15em",
            color: "#fff",
          }}
          className="navi"
        >
          <Link className="a" to="/#testimonials">
            <b>TESTIMONIALS</b>
          </Link>
        </li>
        <li
          style={{
            cursor: "pointer",
            position: "relative",
            letterSpacing: ".15em",
            color: "#fff",
          }}
          className="navi"
        >
          <Link className="a" to="/#industries">
            <b>TEAM</b>
          </Link>
        </li>
        <li
          style={{
            cursor: "pointer",
            position: "relative",
            letterSpacing: ".15em",
            color: "#fff",
          }}
          className="navi"
        >
          <Link className="a" to="/#industries">
            <b>PRICING</b>
          </Link>
        </li>
        <li
          style={{
            cursor: "pointer",
            position: "relative",
            letterSpacing: ".15em",
            color: "#fff",
          }}
          className="navi"
        >
          <Link className="a" to="/#contact">
            <b>CONTACT US</b>
          </Link>
        </li>
      </ul>
      <div className="hamburger">
        {/* <FaBars/> */}
        <nav class="navbar navbar-dark bg-dark fixed-top">
          <div class="container-fluid">
            <div className="logo">
              <a href="#home">
                <img src={Logo} alt="logo" />
              </a>
            </div>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
              aria-controls="offcanvasDarkNavbar"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="offcanvas offcanvas-end text-bg-dark navst"
              tabindex="-1"
              id="offcanvasDarkNavbar"
              aria-labelledby="offcanvasDarkNavbarLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">
                  ALPHAXN
                </h5>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  id="close-button"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/#home"
                    >
                      Home
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/#about"
                    >
                      About Us
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/#services"
                    >
                      Services
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/#industries"
                    >
                      Clients We Serve
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/#testimonials"
                    >
                      Testimonials
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/#industries"
                    >
                      Team
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/#industries"
                    ></Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/#contact"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {sticky && (
        <div
          style={{
            height: "${stickyRef.current?.clientHeight}px",
          }}
        />
      )}

      {/* content */}
    </div>
  );
};

export default NavbarS;
