// FractionalCard.js
import React from "react";
import PropTypes from "prop-types"; // Import PropTypes for type-checking

const FractionalCard = ({ imgSrc, title, content }) => {
  return (
    <div className="fractional-card">
      <img className="fractional-card-img" src={imgSrc} alt={title} />
      <h1 className="fractional-card-heading">{title}</h1>
      <p className="fractional-card-content">{content}</p>
    </div>
  );
};

// Define prop types for better validation
FractionalCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default FractionalCard;
