import React from 'react'
import "./FraudPrevention.css"
import NavbarS from '../../../NavbarS'
import NavbarTest from "../../../NavbarTest"
import Footer from "../../../Footer"
import moneyIcon from "../../../../assets/Quality-assurance-img.jpg"
import ScrollToTop from '../../../ScrollToTop'

const FraudPrevention = () => {
  return (
    <>
      <NavbarS />
      <NavbarTest />
      <div className="Fraud-prevention-container">
        <div>
          <img className="Fraud-prevention-img" src={moneyIcon} alt="plant and money" />
        </div>
        <div className="Fraud-prevention-text">
          <h3
            style={{ paddingBottom: '3em', fontWeight: '600' }}
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-once="True"
            data-aos-delay="100"
          >
            <b>FRAUD PREVENTION SERVICE</b>
          </h3>
          
          <p className="Fraud-prevention-container-content">
            AlphaXN's team of experienced professionals offers comprehensive fraud prevention services.
            By identifying vulnerabilities and implementing controls, we help protect your organization
            from financial fraud. AlphaXN ensures the safety of your business, allowing you to focus
            on growth with the confidence that your assets are secure.
          </p>
        </div>
      </div>
      <ScrollToTop/>
      <Footer/>
    </>
  )
}

export default FraudPrevention
