// src/components/Step.js
import React from 'react';
import { FaCheckCircle, FaChartLine, FaLock } from 'react-icons/fa';
import "./Step.css";  // Use CSS for animation and styling

const Step = ({ stepNumber, title, description, icon }) => {
  return (
    <div className="step">
      <div className="step-icon">{icon}</div>
      <div className="step-content">
        <div className="step-number">{stepNumber}</div>
        <div className="step-title">{title}</div>
        <p className="step-description">{description}</p>
      </div>
    </div>
  );
};

export default Step;
