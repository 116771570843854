import React from "react";
import "./TaxServicesWorkFlow.css";
import SoftwareIcon from "../../../../assets/Tax-Services/SoftwareAccess.svg";

const TaxServicesWorkFlow = () => {
  return (
    <div className="tax-service-container">
      <span
        className="tax-service-main-heading"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-once="true"
        data-aos-delay="100"
        style={{
          padding: "50px",
          color: "#34a853",
          textTransform: "none",
          fontSize: "2.0em",
        }}
      >
        <b>Tax Service Work Flow</b>
      </span>
      <div className="tax-service-card-container">
        <div className="tax-service-card">
          <div className="tax-service-card-image-container">
            <img
              className="tax-service-card-image"
              src={SoftwareIcon}
              alt="software-access-icon"
            />
          </div>
          <div className="tax-service-content-container">
            <h3 className="tax-service-content-heading">
              Stage I – Information and Software Access
            </h3>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxServicesWorkFlow;
