import React, { useState } from 'react';
import "./FloatingForm.css"

const FloatingButtonForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [closing, setClosing] = useState(false); // State to handle close animation
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    areaOfInterest: '',
  });

  const toggleModal = () => {
    if (isOpen) {
      setClosing(true); // Start closing animation
      setTimeout(() => {
        setIsOpen(false);
        setClosing(false); // Reset closing state
      }, 500); // Match this duration to CSS animation time (0.5s)
    } else {
      setIsOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Form submitted!');
    console.log(formData);
    toggleModal();
  };

  return (
    <div>
      <button className="floating-button" onClick={toggleModal}>
        Contact Us
      </button>

      {isOpen && (
        <div className={`modal ${closing ? 'modal-close' : ''}`}>
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>
              &times;
            </span>
            <h2 className='modal-heading'>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="firstName">First name*</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="inline-input"
                />
                <label htmlFor="lastName">Last name*</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="inline-input"
                />
              </div>

              <div>
                <label htmlFor="companyName">Company name</label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  className="inline-input"
                />
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="inline-input"
                />
              </div>

              <label htmlFor="areaOfInterest">Area of Interest:</label>
              <select
                id="areaOfInterest"
                name="areaOfInterest"
                value={formData.areaOfInterest}
                onChange={handleChange}
                required
              >
                <option value="">Select an option</option>
                <option value="Bookkeeping">Bookkeeping</option>
                <option value="Payroll & HR">Payroll & HR</option>
                <option value="Accounts Receivable / Accounts Payable">
                  Accounts Receivable / Accounts Payable
                </option>
                <option value="Fractional CFO">Fractional CFO</option>
                <option value="Tax & Advisory">Tax & Advisory</option>
                <option value="ERC Tax Credits">ERC Tax Credits</option>
              </select>

              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingButtonForm;
