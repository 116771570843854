import React from 'react'
import Countries from "../countries/Countries"
import AboutUs from '../AboutUs'

const ServicesMain = () => {
  return (
    <>
    <div style={{paddingTop: '1em', paddingBottom: '1em', backgroundColor: '#fafcfa'}} id='about1'></div>
   
    <div className='mountbg'>
      <AboutUs/>
      <section className='sec2' id='services1'>
        <div className='linesmall'></div>
        <div className='shapecurve'></div>
        <div className='mtbody'>
          <div className='container' id='services'>
            <div ></div>
            {/* <h3 data-aos='fade' data-aos-duration='1000' data-aos-once='True' data-aos-delay='400'>Services</h3> */}
            <div style={{paddingTop: '2em', paddingBottom: '4em'}} id='services1'> 
              {/* <p data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400" style={{fontWeight: '400'}} className='slogan'>To address these challenges, we offer multiple services to the private equity firms and hedge funds.</p> */}
              {/* <button type="submit" className='FormButton'>
                <span  style={{position: "relative", letterspacing: ".1em"}}>Learn more</span>
              </button> */}
            </div>
            <h3 style={{paddingBottom: '3em',fontWeight: '600'}} data-aos='fade' data-aos-duration='1000' data-aos-once='True' data-aos-delay='100'><b>Services</b></h3>
           <Countries/>
           

                
        

          
            
           
          </div>
          <div className='line4'></div>
        </div>
        <div className='traiangleMid'></div>
        <svg className='cornerimg' viewBox='0 0 500 500' preserveAspectRatio='none'>
          <mask id='svgmask1'>
            <polygon points='0,250 500,0 500,500' fill='#ffffff'></polygon>
          </mask>
          <image xlinkHref='imgicon/people_new.jpg' mask='url(#svgmask1)' width='200%' height='300%' y='-120%' x='-60%' preserveAspectRatio='none'></image>
        </svg>
        <div className='bottomtraingle'></div>
      </section>
      
      <div className='mountbgimg'>
        <span className='mountimage'>
          <img alt='mount' style={{opacity: '.9'}} sizes='100vw' src='imgicon/mount_green.png' decoding='async'></img> 
        </span>
      </div>
    </div>
     
    </>
  )
}

export default ServicesMain
