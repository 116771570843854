import React from 'react'
import NavbarS from '../../../NavbarS'
import NavbarTest from "../../../NavbarTest"
import Footer from "../../../Footer"
import forensicsIcon from "../../../../assets/Quality-assurance-img.jpg"
import "./ForensicsSupport.css"
import ScrollToTop from '../../../ScrollToTop'

const ForensicsSupport = () => {
  return (
    <>
      <NavbarS />
      <NavbarTest />
      <div className="Forensics-support-container">
        <div>
          <img className="Forensics-support-img" src={forensicsIcon} alt="forensics investigation" />
        </div>
        <div className="Forensics-support-text">
          <h3
            style={{ paddingBottom: '3em', fontWeight: '600' }}
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-once="True"
            data-aos-delay="100"
          >
            <b>FORENSICS SUPPORT SERVICE</b>
          </h3>
          
          <p className="Forensics-support-container-content">
            AlphaXN offers forensic accounting services to help organizations investigate financial discrepancies
            and irregularities. Our team specializes in uncovering fraud, financial misconduct, and errors,
            providing comprehensive analysis and evidence to support legal actions if necessary.
          </p>
        </div>
      </div>

      <ScrollToTop/>
      <Footer />
     
    </>
  )
}

export default ForensicsSupport
