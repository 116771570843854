import React from 'react';
import {motion} from 'framer-motion';
// import { Link } from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop';
// import { HashLink as Link } from 'react-router-hash-link';
import { Link } from "react-scroll";
import NavbarS from '../../NavbarS';
import { Link as link} from 'react-router-dom';
import Cokies from '../../Cokies';
import Footer from '../../Footer';

const Service4 = () => {
  return (
    <div id="main">
       
      <NavbarS/>
      <Cokies/>
      
      <main style={{backgroundColor: 'white'}}>
        <section className='bannerstyle'>
          <div style={{position: 'relative', width: '100%', height: '98%', backgroundColor: '#141414'}}>
            <div className='rightcontent1' style={{left: '60px'}}>
              <span className='spancontent1' style={{color: 'white',fontFamily: '"Raleway", sans-serif' }}>
                <span>
                  <h4>Here To Help</h4><br/>
                  <p style={{color: 'white'}}>Each day is an opportunity to change and improve. We can support you anywhere in your investment journey with a unique plan that changes and grows with you. Our team members have decades of experience. As you move ahead in your business, we partner with you to realize your business objectives through professional services.</p><br/>
                  <h4>Building Trust and Confidence Everyday.</h4>
                </span>
              </span>
            </div>
            <div className='cornerleft1'></div>
          </div>
          
          <div className='justdiv'>
            <div className='leftban'>
              <h3>
                <span data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" style={{paddingLeft: '75px',color: 'white',textTransform: 'none'}}><b>Services</b></span>
                <div style={{ width: '40px', position: 'absolute', top: '50%', left: '0', maxWidth: '40px', height: '1px', backgroundColor: '#fff'}}></div>
              </h3>
            </div>
            <div className='leftbgimg'>
              <div style={{height: '100%'}}>
                <div style={{position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%'}}>
                  <span className='imagebgleft'>
                    <img alt='city' src='imgicon/services_comp4.jpg' className='bgimagecity' loading='lazy' decoding='sync'></img>
                    <motion.img animate={{scale:1.2}} transition={{duration: 3}} alt='city' src='imgicon/services_comp4.jpg' className='bgimagecity' loading='lazy' decoding='sync' ></motion.img>
                  </span>
                </div>
                <motion.div initial={{y: -600}} animate={{y: 0}} transition={{duration: 2}} className='transparent image'></motion.div>
              </div>
              
              <div className='righttri'></div>
              <div className='righttri1'></div>
            </div>
            <div className='transparent2 image'></div>
          </div>
        </section>
        
        <div className='mtbody' style={{backgroundColor: "white"}}>
          <div className='container'>

            {/* 1.DTM */}
            <div className='itemboxnew'>
              {/* You can add any new content related to Service4 here */}
            </div>
          </div>
        </div>

        {/* colorful */}
        <div className="container-fluid mb-5 container">
          <div className="row">
            <div className="col-md-4">
              <div className="box">
                <div className="our-services settings" style={{padding: '0 45px'}}>
                  <div className="icon"> <img src='imgicon/icon_grad/1.png'></img> </div>
                  <h4 className='newhead'>Deal Team Augmentation</h4>
                  <div className='infobullets'>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Financial modelling</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Document based due-diligence</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Market and competitive analysis</p>
                    <p style={{fontFamily: '"Raleway", sans-serif',paddingBottom: '7.7em'}}>Reports and decks preparation</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="box">
                <div className="our-services speedup" style={{padding: '0 32px'}}>
                  <div className="icon"> <img src='imgicon/icon_grad/5.png'></img> </div>
                  <h4 className='newhead'>Data Management and Analytics</h4>
                  <div className='infobullets'>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Integrations</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Data retrieval</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Cloud Reporting</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>No Code Analytics</p>
                    <p style={{fontFamily: '"Raleway", sans-serif',paddingBottom: '5.4em'}}>Dashboards with interactive charts</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="box">
                <div className="our-services privacy">
                  <div className="icon"> <img src='imgicon/icon_grad/3.png'></img> </div>
                  <h4 className='newhead'>Accounting</h4>
                  <div className='infobullets'>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Chart of Accounts preparation</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Ledger Maintenance</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Bank reconciliation</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Financial reports</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Management reports</p>
                    <p style={{fontFamily: '"Raleway", sans-serif'}}>Cash flow management</p>
                    <p style={{fontFamily: '"Raleway", sans-serif',paddingBottom: '1em'}}>Receivable and payable management</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className='cutimage'>
          <div className='cutimage1'>
            <div className='emptybg'></div>
            <span className='imagestyle1'>
              <span className='insidespan'>
                <img alt='image' srcSet='imgicon/test1.jpg' style={{display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: '1', border: '0px', margin: '0px', padding: '0px'}}></img>
              </span>
              <img alt='city' className='imagejpg' decoding='async' src='imgicon/mechf_g.jpg'></img>
            </span>
          </div>
        </section>
      </main>

      <Footer/>
      <ScrollToTop/>
      
    </div>
  )
}

export default Service4;
