import React from 'react';
import './Team.css'; // Link to the CSS file

const teamMembers = [
  {
    name: 'Akshay Kumar',
    role: 'Founder / CEO',
    image: 'https://via.placeholder.com/150',
    description: 'Subscribe Easy Tutorials Youtube Channel watch more videos',
  },
  {
    name: 'Raima Ray',
    role: 'Business Head',
    image: 'https://via.placeholder.com/150',
    description: 'Subscribe Easy Tutorials Youtube Channel watch more videos',
  },
  {
    name: 'Arjun Kapur',
    role: 'UI Design',
    image: 'https://via.placeholder.com/150',
    description: 'Subscribe Easy Tutorials Youtube Channel watch more videos',
  },
  {
    name: 'Alia Shen',
    role: 'Marketing Head',
    image: 'https://via.placeholder.com/150',
    description: 'Subscribe Easy Tutorials Youtube Channel watch more videos',
  }
];

const Team = () => {
  return (
    <div className="team-section" id="team">
      <h2>TEAM</h2>
      <p>Assumenda non repellendus distinctio nihil dicta sapiente...</p>
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div className="team-card" key={index}>
            <img src={member.image} alt={member.name} className="team-image" />
            <h3>{member.name}</h3>
            <p className="role">{member.role}</p>
            <p>{member.description}</p>
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;

