import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPhone, faEnvelope, faMapMarkerAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';

import ScrollToTop from '../../ScrollToTop';
// import { HashLink as Link } from 'react-router-hash-link';
import { FaCheckCircle, FaChartLine, FaLock } from 'react-icons/fa'; // Icons
import NavbarS from '../../NavbarS';
import { Link } from 'react-router-dom';
import Cokies from '../../Cokies';
import Footer from '../../Footer';
import CountryServiceCard from "../Service-sub-sections/CountryServiceCard";
import AssureIcon from "../../../assets/icon_grad/1.png"; // Icon path for all cards
import "./Service1.css"
import ServicesImg from "../../../assets/imgicon/services_comp2.jpg"
import ServicesImg2 from "../../../assets/imgicon/test1.jpg"
import ServicesImg3 from "../../../assets/imgicon/mechf_g.jpg"
import Steps from '../Service-sub-sections/Step';
import AssuranceSupportServices from '../Service-sub-sections/AssuranceSupportServices';
const Service1 = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate('/'); // Navigates back to the home page
    };
  const subServices = [
    {
      title: 'Quality Assurance',
      route: '/us/service1/quality-assurance',
      image: AssureIcon, 
      hasLink: true ,
    },
    {
      title: 'Audit Support - Statutory audit, Internal audit, EBP audit, SOX compliance, Non-profit audit',
      
      image: AssureIcon, 
      hasLink: false ,
    },
    {
      title: 'Internal Control Risk Assessment',
      
      image: AssureIcon, 
      hasLink: false ,
    },
    {
      title: 'Fraud Prevention and Detection',
      route: '/us/service1/fraud-prevention',
      image: AssureIcon, 
      hasLink: true ,
    },
    {
      title: 'Forensics Support Service',
      route: '/us/service1/forensics-support',
      image: AssureIcon, 
      hasLink: true ,
    },
    {
      title: 'Interpretation of U.S GAAP, U.S GAAS and IFRS',
     
      image: AssureIcon, 
      hasLink: false ,
    },
    {
      title: 'Preparation of Financial Statements (no conflict of interest)',
    
      image: AssureIcon, 
      hasLink: false ,
    },
  ];
  const steps = [
    {
      number: '01',
      title: 'Testing Transactions',
      description: 'Sampling and verifying the accuracy of recorded financial transactions.',
      icon: <FaCheckCircle />
    },
    {
      number: '02',
      title: 'Analytical Procedures',
      description: 'Comparing financial data to industry benchmarks or identifying unusual trends.',
      icon: <FaChartLine />
    },
    {
      number: '03',
      title: 'Evaluating Internal Controls',
      description: 'Assessing the effectiveness of internal controls in safeguarding assets and ensuring accurate financial reporting.',
      icon: <FaLock />
    },
  ];
  return (
    <div id="main">
      <NavbarS />
      <Cokies />

      <main style={{ backgroundColor: 'white' }}>
        <section className='bannerstyle'>
          <div style={{ position: 'relative', width: '100%', height: '98%', backgroundColor: '#141414' }}>
            <div className='rightcontent1' style={{ left: '60px' }}>
              <span className='spancontent1' style={{ color: 'white', fontFamily: '"Raleway", sans-serif' }}>
                <span>
                  <h4>We offer a comprehensive suite of services to support</h4><br />
                  
                    <p>- CPA firms</p>
                    <p>- Other professional services firm such as Forensics Services, Valuation Services,
                    and Law firms</p>
                 
                  
                  <h4>Our teams can support in statutory audits, internal audits, EBP audits, SOX compliance audits,
                  and non-profit audits.</h4>
                </span>
              </span>
            </div>
            <div className='cornerleft1'></div>
          </div>

          <div className='justdiv'>
            <div className='leftban'>
              <h3>
                <span data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" style={{ paddingLeft: '75px', color: 'white', textTransform: 'none' }}>
                  <b>Assurance Support services</b>
                </span>
                <div style={{ width: '40px', position: 'absolute', top: '50%', left: '0', maxWidth: '40px', height: '1px', backgroundColor: '#fff' }}></div>
              </h3>
            </div>
            <div className='leftbgimg'>
              <div style={{ height: '100%' }}>
                <div style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                  <span className='imagebgleft'>
                    <img alt='city' src={ServicesImg} className='bgimagecity' loading='lazy' decoding='sync'></img>
                    <motion.img animate={{ scale: 1.2 }} transition={{ duration: 3 }} alt='city' src= {ServicesImg} className='bgimagecity' loading='lazy' decoding='sync'></motion.img>
                  </span>
                </div>
                <motion.div initial={{ y: -600 }} animate={{ y: 0 }} transition={{ duration: 2 }} className='transparent image'></motion.div>
              </div>

              <div className='righttri'></div>
              <div className='righttri1'></div>
            </div>
            <div className='transparent2 image'></div>
          </div>
        </section>
           {/* ASSURANCE SUB SERVICES*/}
        
           <div className="country-service-card-container">
  {subServices.map((service, index) => (
    <CountryServiceCard
      key={index}
      title={service.title}
      icon={service.image}
      route={service.route} // Pass the route here
    />
  ))}
</div>

       {/* ASSURANCE ERVICES PROCESS*/}
        <div className="flow-chart">
        <span data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" style={{ padding: '50px', color: '#34a853', textTransform: 'none', fontSize:'2.0em' }}>
                  <b>What our process involves:</b>
        </span>
        <p className='flow-chart-para'>AlphaXN audit support services includes a detailed review of a company's financial statements, accounting records, and internal controls. The audit-supportprofessional in our team will perform various procedures such as:</p>
        {steps.map((step, index) => (
        <Steps
          key={index}
          stepNumber={step.number}
          title={step.title}
          description={step.description}
          icon={step.icon}
                                 />
          ))}
        </div>

          {/* ASSURANCE SERVICES USES*/}
          <AssuranceSupportServices/>


           


     
    <div className='back-button-container'>
    <button onClick={handleBackClick} className="back-button">
        Back
      </button>
    </div>
          

        <section className='cutimage'>
          <div className='cutimage1'>
            <div className='emptybg'></div>
            <span className='imagestyle1'>
              <span className='insidespan'>
                <img alt='test' srcSet={ServicesImg2} style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: '1', border: '0px', margin: '0px', padding: '0px' }}></img>
              </span>
              <img alt='city' className='imagejpg' decoding='async' src={ServicesImg3}></img>
            </span>
          </div>
        </section>
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default Service1;
