import React from 'react'
import './Testimonials.css'

const Testimonials = () => {
    return (
        <div id="testimonials">
            {/* <div style={{textAlign: 'left'}}>
              <h4 data-aos='fade-up' data-aos-duration="700" data-aos-once="True" ><span><b>Trusted by leading organizations</b></span></h4>
            </div> */}
            <h3 style={{marginTop: '2em',marginBottom: '1em'}} data-aos='fade' data-aos-duration='700' data-aos-once='True' data-aos-delay='50'><b>Trusted by leading organizations</b></h3>
            <section class="t-bq-section" id="emma">
                <div class="t-bq-wrapper t-bq-wrapper-boxed">
                    <div class="t-bq-quote t-bq-quote-emma">
                        <div class="t-bq-quote-emma-qmark"><span>&#10077;</span></div>
                        <div class="t-bq-quote-emma-userpic_LA"></div>
                        <div class="t-bq-quote-emma-base">
                            <blockquote class="t-bq-quote-emma-text" cite="Senior Accounting Manager, Health Care Company, Los Angeles, United States">
                            I have had the pleasure of working with ALPHAXN for the past 4 months.  They have been a key factor in assisting our company needs, especially during some critical transitions in our company.  ALPHAXN
                             demonstrates an impressive understanding of accounting principles.  Their expertise, commitment to accuracy, and attention to detail has been amazing.  Not only does ALPHAXN provide a depth of knowledge in their field, but also provides excellent customer service by being friendly, flexible, prompt, and thorough.  I would recommend ALPHAXN to assist in any accounting needs.
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>

            <section class="t-bq-section" id="emma">
                <div class="t-bq-wrapper t-bq-wrapper-boxed">
                    <div class="t-bq-quote t-bq-quote-emma">
                        <div class="t-bq-quote-emma-qmark"><span>&#10077;</span></div>
                        <div class="t-bq-quote-emma-userpic_NY"></div>
                        <div class="t-bq-quote-emma-base">
                            <blockquote class="t-bq-quote-emma-text" cite="Co-Founder, Private Equity firm, New York, United States">
                            “We had a great experience engaging with ALPHAXN on a time sensitive diligence project in 1Q 2024. ALPHAXN assembled a high-quality team 2-3 people to help put together presentation materials for our Investment Committee on a potential investment over the span of 1 month. The ALPHAXN team had good attention to detail, could follow our instructions, and could communicate in a timely and efficient manner to get the project across the finish line. Overall, this was a cost effective and time effective way to progress with our investment opportunity and I would highly recommend using them again.”
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>

            <section class="t-bq-section" id="emma">
                <div class="t-bq-wrapper t-bq-wrapper-boxed">
                    <div class="t-bq-quote t-bq-quote-emma">
                        <div class="t-bq-quote-emma-qmark"><span>&#10077;</span></div>
                        <div class="t-bq-quote-emma-userpic_NY2"></div>
                        <div class="t-bq-quote-emma-base">
                            <blockquote class="t-bq-quote-emma-text" cite="Managing Partner, Private Investment firm, New York, United States">
                            <span>“ALPHAXN team built pre-acquisition financial models for few of our deals in the past 12 months. For each of the deal, working with ALPHAXN felt less like hiring a consultant and more like having a strong financial professional join the team. They took the time to understand our many requirements and from day one, they put us at ease with their friendly demeanor and clear explanations.<br></br></span><span>The models itself? It wasn't just some spreadsheets. ALPHAXN built financial models that we could actually understand and use in our deals. They listened patiently to our ideas and found creative ways to incorporate them.<br></br></span>    

ALPHAXN kept us informed at every step, readily addressed our questions, and actively incorporated our feedback. Their responsiveness and collaborative approach instilled immense confidence in their work. ALPHAXN built-models gave us the confidence to make decisions effectively. ALPHAXN is more than just a service provider. They're a partner who genuinely cares about our success. We can't recommend them enough!"
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="t-bq-section" id="sophia">
                <div class="t-bq-wrapper t-bq-wrapper-boxed">
                    <div class="t-bq-quote t-bq-quote-sophia">
                        <div class="t-bq-quote-sophia-base">
                            <blockquote class="t-bq-quote-sophia-text" cite="Братья Стругацкие">
                                And the child meekly looks at you and thinks: you, of course, are an adult, hefty, you can flog me, however, as you have been a fool since childhood, you remain it now, and you will die fool, but this is not enough for you, you want to turn me into a fool as well...
                            </blockquote>
                        </div>
                        <div class="t-bq-quote-sophia-author"><cite>Strugatsky brothers</cite></div>
                        <div class="t-bq-quote-sophia-userpic"></div>
                    </div>
                </div>
            </section> */}

            {/* s */}
            </div>
    )
}

export default Testimonials