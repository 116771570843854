import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import ScrollToTop from '../../ScrollToTop';
import NavbarS from '../../NavbarS';
import { Link } from 'react-router-dom';
import Cokies from '../../Cokies';
import Footer from '../../Footer';
import CountryServiceCard from "../Service-sub-sections/CountryServiceCard";
import AssureIcon from "../../../assets/icon_grad/1.png"; 
import "./Service1.css"
import ServicesImg from "../../../assets/imgicon/services_comp2.jpg"
import ServicesImg2 from "../../../assets/imgicon/test1.jpg"
import ServicesImg3 from "../../../assets/imgicon/mechf_g.jpg"
import TaxServicesWorkFlow from './Sub-Services/TaxServicesWorkFlow';
import usLogo from "../../../assets/us.png";

const Service1 = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate('/'); // Navigates back to the home page
    };
  const subServices = [
    {
      title: 'Business and corporate taxes',
      route: '/us/service2/quality-assurance',
      image: AssureIcon, // Using the same icon for all services
    },
    {
      title: 'C-corps',
      route: '/us/service2/audit-support',
      image: AssureIcon, // Using the same icon for all services
    },
    {
      title: 'S-corps',
      route: '/us/service2/risk-assessment',
      image: AssureIcon, // Using the same icon for all services
    },
    {
      title: 'Partnerships',
      route: '/us/service2/fraud-prevention',
      image: AssureIcon, // Using the same icon for all services
    },
    
  ];

  return (
    <div id="main">
      <NavbarS />
      <Cokies />
      <img className="top-img" src={usLogo} alt="us-logo" />

      <main style={{ backgroundColor: 'white' }}>
        <section className='bannerstyle'>
          <div style={{ position: 'relative', width: '100%', height: '98%', backgroundColor: '#141414' }}>
            <div className='rightcontent1' style={{ left: '60px' }}>
              <span className='spancontent1' style={{ color: 'white', fontFamily: '"Raleway", sans-serif' }}>
                <span>
                  <h4>We support CPA firms and Enrolled Agents (EAs). Unpack your growth potential while relieving
yourself of operating headaches by working with us. With our help, the following benefits
immediately flow to you:</h4><br />
                    <ul>
                      <li>Take on more clients to scale your business without increase in operating challenges</li>
                      <li>Upto 70% savings in business costs</li>
                      <li> Fast turnaround time</li>  
                    </ul>
                    
                  
                 
                  
                  <h4>Our teams can support in statutory audits, internal audits, EBP audits, SOX compliance audits,
                  and non-profit audits.</h4>
                </span>
              </span>
            </div>
            <div className='cornerleft1'></div>
          </div>

          <div className='justdiv'>
            <div className='leftban'>
              <h3>
                <span data-aos='fade-up' data-aos-duration="700" data-aos-once="True" data-aos-delay="100" style={{ paddingLeft: '75px', color: 'white', textTransform: 'none' }}>
                  <b>Tax preparation and review</b>
                </span>
                <div style={{ width: '40px', position: 'absolute', top: '50%', left: '0', maxWidth: '40px', height: '1px', backgroundColor: '#fff' }}></div>
              </h3>
            </div>
            <div className='leftbgimg'>
              <div style={{ height: '100%' }}>
                <div style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                  <span className='imagebgleft'>
                    <img alt='city' src={ServicesImg} className='bgimagecity' loading='lazy' decoding='sync'></img>
                    <motion.img animate={{ scale: 1.2 }} transition={{ duration: 3 }} alt='city' src= {ServicesImg} className='bgimagecity' loading='lazy' decoding='sync'></motion.img>
                  </span>
                </div>
                <motion.div initial={{ y: -600 }} animate={{ y: 0 }} transition={{ duration: 2 }} className='transparent image'></motion.div>
              </div>

              <div className='righttri'></div>
              <div className='righttri1'></div>
            </div>
            <div className='transparent2 image'></div>
          </div>
        </section>

        <div className='mtbody' style={{ backgroundColor: "white" }}>
          <div className='container'>
            <div className='itemboxnew'>
              {/* Add additional content or remove if unnecessary */}
            </div>
          </div>
        </div>

        {/* Tax Services Sub-Services */}
        
        <div className="country-service-card-container">
  {subServices.map((service, index) => (
    <CountryServiceCard
      key={index}
      title={service.title}
      icon={service.image}
      route={service.route} // Pass the route here
    />
  ))}
</div>

     {/* Tax Services Workflow */}
      <TaxServicesWorkFlow/>
    <div className='back-button-container'>
    <button onClick={handleBackClick} className="back-button">
        Back
      </button>
    </div>
          

        <section className='cutimage'>
          <div className='cutimage1'>
            <div className='emptybg'></div>
            <span className='imagestyle1'>
              <span className='insidespan'>
                <img alt='test' srcSet={ServicesImg2} style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: '1', border: '0px', margin: '0px', padding: '0px' }}></img>
              </span>
              <img alt='city' className='imagejpg' decoding='async' src={ServicesImg3}></img>
            </span>
          </div>
        </section>
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default Service1;
