import React from "react";
import Service from "../Us/Services";
import Cokies from "../../Cokies";
import Hero from "../../Hero";
import Navbar from "../../Navbar";
import NavbarTest from "../../NavbarTest";
import SecondLast from "../../SecondLast";
import Testimonials from "../../Testimonials";
import Train from "../../Train";
import ContactUs from "../../ContactUs";
import PricingComponent from "../../Pricing/PricingComponent";
import Footer from "../../Footer";
import Team from "../../Team/Team";
import usLogo from "../../../assets/us.png"
const us = () => {
  return (
    <>
      <Cokies />
      <Hero />

      <Navbar />
      <NavbarTest />
     
      <Service />
      <SecondLast />
      <Testimonials />
      <Team />
      <Train />
      <PricingComponent />
      <ContactUs />

      <Footer />
    </>
  );
};

export default us;
