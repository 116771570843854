import React from "react";
import NavbarS from "../../../NavbarS";

import Footer from "../../../Footer";
import NavbarTest from "../../../NavbarTest"
import "./QualityAssurance.css"
import moneyIcon from "../../../../assets/Quality-assurance-img.jpg"
import ScrollToTop from "../../../ScrollToTop";
import Breadcrumb from "../../../Breadcrumb";


const QualityAssurance = () => {
  const breadcrumbPaths = [
    { name: 'Services', link: '#services' },
    { name: 'US', link: '/us' },
    { name: 'Assurance', link: '/us/service1' },
    { name: 'Quality-assurance', link: '/us/service1/quality-assurance' }
  ];

  return (
    <>
    <NavbarS/>
    <NavbarTest/>
    <div className="bread">
    <Breadcrumb paths={breadcrumbPaths} />
    </div>
    
    <div className="Quality-assurance-container">
   
    <div >
      <img className="Quality-assurance-img" src={moneyIcon} alt="plant and money"/>
    </div>
    <div className="Quality-assurance-text">
    <h3 style={{paddingBottom: '3em',fontWeight: '600'}} data-aos='fade' data-aos-duration='1000' data-aos-once='True' data-aos-delay='100'><b>QUALITY ASSURANCE SERVICE</b></h3>
    
    <p className="Quality-assurance-container-content"> 
     AlphaXN team of experienced professionals can deliver quality assurance
     by supervising the work performed by your team members. Growing
     managerial capability takes time and valuable resource. Working with
     AlphaXN would allow you to take on more clients and to grow your
     business with confidence without worrying about the limitations of
     limited managerial talent within your organization.
   </p>
   
    </div>
    </div>
    
    <ScrollToTop/>
    <Footer/>
   
   
  </>
  );
};

export default QualityAssurance;
