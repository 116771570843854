import React from 'react'
import ContactUs from './ContactUs'
import SecondLast from './SecondLast'
import Train from './Train'
import 'animate.css'
import Hero from './Hero'
import Navbar from './Navbar'
import NavbarTest from './NavbarTest'
import Cokies from './Cokies'
import Testimonials from './Testimonials'
import PricingComponent from './Pricing/PricingComponent'
import Footer from './Footer'
import Team from './Team/Team'
// import Services from './Services/Us/Services'
import ServicesMain from './Services/ServicesMain'



// Cookies.set('name', 'value', { expires: 7 });
// const value = Cookies.get('name');

function Home() {
  
  // const [user, setUser] = useState({});

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     const response = await axios.get('/api/user');
  //     setUser(response.data);
  //     Cookies.set('user', JSON.stringify(response.data), { expires: 7 });
  //   };
  //   fetchUser();
  // }, []);

  // useEffect(() => {
  //   const user = Cookies.get('user');
  //   if (user) {
  //     setUser(JSON.parse(user));
  //   }
  // }, []);
    
  return (
    <div>
        <Cokies/>
        <Hero/>
        
        <Navbar/>
        <NavbarTest/>
        <ServicesMain/>
        <SecondLast/>
       
        <Testimonials/>
        <Team/>
        <Train/>
        <PricingComponent/>
        <ContactUs/>
        
        <Footer/>
       
        
    </div>
  )
}

export default Home