import { Card } from '@mui/material'
import React from 'react'
import 'animate.css'
import { useState } from 'react'
import {motion} from 'framer-motion'
import { useFormValidation } from './useFormValidation'
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom'
import './footer_style.css'


export const isText = RegExp(/^[A-Z ]{3,}$/i);
export const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

const stateShcema = {
  name: {
    value: "",
    error: ""
  },
  email: {
    value: "",
    error: ""
  },
  subject: {
    value: "",
    error: ""
  },
  message: {
    value: "",
    error: ""
  }
};

const validateShcema = {
  name: {
    required: true,
    validator: {
      regEx: isText,
      error: "Please provide a valid name"
    }
  },
  email: {
    required: true,
    validator: {
      regEx: isEmail,
      error: "Invalid email address"
    }
  },
  subject: {
    required: true,
    validator: {
      regEx: isText,
      error: "Please enter a valid subject"
    }
  },
  message: {
    required: true,
    validator: {
      length: 5,
      error: "Minimum 5 characters required."
    }
  }
};

const ContactUs = () => {

  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);

  const handleSubmit = event => {
    event.preventDefault();
    console.log(event.target.name.value)
    console.log(event.target.email.value)
    console.log(event.target.subject.value)
    console.log(event.target.message.value)
    




    fetch("https://app.moxiedb.com/api/apiv1/admin/alphaxn_contact_us?name=" + event.target.name.value + '&mail=' + event.target.email.value + '&subject=' + event.target.subject.value + '&message=' + event.target.message.value)
      .then(res => res.json())
      .then(
        (result) => {
          console.log('result: ', result)
          if(result.status){
            setAlert(true);
            setAlertMsg(result.msg);
            setTimeout(() => {
              setAlertMsg(null)
            }, 3000);
            event.target.reset();
          }

        },
        (error) => {
          
        }
      )



  }

  const { state, disable, handleChange, handleInput } = useFormValidation(
    stateShcema,
    validateShcema,
    handleSend
  );
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const { name, email, phone, message } = state;

  function handleSend() {
    setLoading(true);

    // Just for testing
    setTimeout(() => {
      setResponseMessage("it works!");
      setLoading(false);
    }, 3000);
  }

  return (
    <div className='FootEr' id='contact'>
      <div className='FooterBox container'>
        <h3><b>CONTACT US</b></h3>
        <div className='FooterContent'>
          <div className='Divider' data-aos="slide-down" data-aos-once="True" data-aos-delay="200" style={{height: "100%"}}></div>
          <div className='LeftContent'>
            <div className='FootTxt'>
              <p className='TxtPara' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400"><span>We look forward to getting in touch with you</span></p>
              <div className='UnderLine' style={{width: "100px"}}></div>
              {/* <p className='TxtPara' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="500" style={{margin: "2.5em 0"}}><a href='snimmagadda@empact.ai'>	snimmagadda@empact.ai</a></p> */}
              <p className='TxtPara' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="600" style={{margin: "2.5em 0"}}>New York, U.S.A</p>
              {/* <small className='TxtPara' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="700"  style={{margin: "2.5em 0"}}><span style={{ position: "relative", lineheight: "1.2rem",opacity: ".5", fontweight: "100" ,fontsize: ".8rem" ,maxwidth: "350px", marginleft: "auto"}}>The information contained in this web site is for information purposes only and should not be construed as financial or investment advice on any subject matter. Alphaxn does not warrant the accuracy of the information provided on this web site</span></small> */}
            </div>
          </div>

          {/* right side */}
          <div className='RightContent' data-aos='fade-up' data-aos-duration="1000" data-aos-once="True" data-aos-delay="400">
              <h4>Get in touch</h4>
              <div>
                <form onSubmit={handleSubmit}>
                  <div className='Form1'>
                    <div className='Form12'>
                      {/* <label>name</label> */}
                      <input className='InputBox' type='text' placeholder='Name' style={{backgroundimage:"none"}} name='name' value={name.value} onChange={handleChange} ></input>
                      {name.error ? (
                    <div>{name.error}</div>
                    ) : name.value ? (
                      <p color="green"></p>
                    ) : (
                      ""
                    )}
                    </div>

                    <div className='Form12'>
                      {/* <label>surname</label> */}
                      <input className='InputBox' type='email' placeholder='Email' style={{backgroundimage:"none"}} name='email' value={email.value} onChange={handleChange}></input>
                      {email.error ? (
                        <div>{email.error}</div>
                      ) : email.value ? (
                        <div color="#fff"></div>
                      ) : (
                        ""
                      )}
                    </div> 
                  </div>
                  <div className='FormEmail'>
                    {/* <label>Email</label> */}
                    <input className='InputBox' type="text" placeholder='Subject' name='subject'></input>
                  </div>
                  <div className='FormEmail'>
                    <textarea className='InputBox' placeholder='Message' name="message" value={message.value} onChange={handleChange}></textarea>
                    {message.error && <div>{message.error}</div>}
                  </div>
                  <div style={{paddingtop: "2em"}}>
                    <button type="submit" className='FormButton'>
                      <motion.a className='btnbtn1' whileHover={{scale: 1.1, textShadow: "0px 0px 8px #34a853", boxShadow: "0px 0px 8px #34a853",fill: '#34a853', backgroundColor: '#34a853', color:'#fff'}} style={{position: "relative", letterspacing: ".1em",color: '#34a853'}}>Submit</motion.a>
                    </button>
                  </div>
                </form>
                <div style={{paddingTop: '3em', color: '#34a853'}}>{alertMsg}</div>
              </div>
          </div>
        </div>
      </div>
     
        
        {/* <div className="d-flex justify-content-center">
          <div className="p-2" data-aos="fade-up" data-aos-once="True" style={{ textAlign: 'right' }}>
            <p>For all inquiries, please email us or use the form to the right.</p>
            <p className='animate__animated animate__fadeInUp'><b>info@gmail.com</b></p>
            <p className='animate__animated animate__fadeInUp'>270 address</p>
          </div>
          <div>
            <div className='lineAnima'></div>
          </div>
          <div className="p-2">
          <div className="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Email address</label>
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
          </div>
          <p>this is exmaple</p>
          <p>this is exmaple</p>
          </div>
        </div> */}

    </div>
  )
}

export default ContactUs