import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';

import Cokies from './Cokies';
import Footer from './Footer';

const SubServiceDetail = () => {
  const { serviceName } = useParams();

  // Sample data for sub-services (this could be fetched from an API)
  const subServicesData = {
    'quality-assurance': {
      title: 'Quality Assurance',
      description: 'Our Quality Assurance services help ensure that your processes and products meet defined standards and requirements. We provide comprehensive testing and evaluation to identify areas for improvement.',
    },
    'audit-support': {
      title: 'Audit Support',
      description: 'We offer extensive audit support including statutory, internal, and compliance audits. Our experts ensure that all regulatory requirements are met and that your operations remain compliant.',
    },
    'risk-assessment': {
      title: 'Internal Control Risk Assessment',
      description: 'Our risk assessment services identify potential risks in your internal controls and provide strategies to mitigate them, ensuring that your organization operates effectively and efficiently.',
    },
    'fraud-prevention': {
      title: 'Fraud Prevention and Detection',
      description: 'We provide robust fraud prevention and detection services to help you safeguard your assets and ensure that your organization operates with integrity and transparency.',
    },
    'forensics-support': {
      title: 'Forensics Support Service',
      description: 'Our forensic support services involve investigating financial discrepancies and fraud allegations to provide you with a thorough understanding of potential risks and liabilities.',
    },
    'interpretation-gaap': {
      title: 'Interpretation of U.S GAAP, U.S GAAS and IFRS',
      description: 'We assist in the interpretation and application of U.S GAAP, U.S GAAS, and IFRS, ensuring that your financial reporting is compliant with all relevant standards.',
    },
    'financial-statements': {
      title: 'Preparation of Financial Statements',
      description: 'Our financial statement preparation services ensure that your financial reports accurately reflect your organization’s financial position while maintaining independence and compliance.',
    },
  };

  const serviceDetails = subServicesData[serviceName] || {
    title: 'Service Not Found',
    description: 'The service you are looking for does not exist.',
  };

  return (
    <div id="main">
      <Navbar />
      <Cokies />

      <main style={{ backgroundColor: 'white', padding: '2em' }}>
        <h1>{serviceDetails.title}</h1>
        <p>{serviceDetails.description}</p>
      </main>
      
      <Footer />
    </div>
  );
}

export default SubServiceDetail;
