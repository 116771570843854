import React from 'react'
import { Link } from 'react-router-dom'

const AboutUs = () => {
  return (
    <section className="sec1" id="about">
          <div className="sec1cont">
            <h3
              data-aos="fade"
              data-aos-duration="1000"
              data-aos-once="True"
              data-aos-delay="100"
            >
              <b>about us</b>
            </h3>
            <div className="line3" style={{ height: "165%" }}></div>
            <div className="mountbgtxt">
              <div style={{ textAlign: "right" }}>
                <h4
                  data-aos="fade-up"
                  data-aos-duration="700"
                  data-aos-once="True"
                >
                  <span>
                    <b>Get on top of it and stay. Always.</b>
                  </span>
                </h4>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="True"
                data-aos-delay="110"
              >
                <p className="parah" style={{ marginBottom: "0em" }}>
                  <b
                    style={{
                      position: "relative",
                      top: "-11px",
                      lineHeight: "2.4",
                    }}
                  >
                    AlphaXN strives everyday to be your organization’s financial
                    compass by earning your trust. AlphaXN seamlessly integrates
                    with your team to deal with the full spectrum of business
                    challenges, from everyday tasks to strategic initiatives in
                    your Accounting and finance operations.
                  </b>
                  
                </p>
                <button type="submit" className="FormButton more_btn">
                  <span
                    style={{
                      position: "relative",
                      letterspacing: ".1em",
                      color: "#ed462f",
                    }}
                  >
                    <Link style={{ color: "#34a853" }} to="/aboutus">
                      <b>More</b>
                    </Link>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </section>
  )
}

export default AboutUs
