import 'animate.css'
// import NewTab from './comp/NewTab';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from './comp/Home';
import FloatingButtonForm from './comp/Get-A-Quote/FloatingForm';
import ContactTab from './comp/ContactTab';

import CookiePage from './comp/CookiePage';
import AboutUsInfo from './comp/AboutUsInfo';
import Us from "./comp/Services/Us/us"
import Canada from './comp/Services/Canada/canada';
import Australia from './comp/Services/Australia/Australia';
import Service1 from './comp/Services/Us/Service1';
import Service2 from './comp/Services/Us/Service2';
import Service3 from './comp/Services/Us/Service3';
import Service4 from './comp/Services/Us/Service4';
import Service5 from "./comp/Services/Canada/Service1"
import Service6 from "./comp/Services/Canada/Service2"
import Service7 from "./comp/Services/Canada/Service3"
import Service8 from "./comp/Services/Canada/Service4"
import Service9 from "./comp/Services/Australia/Service1"
import Service10 from "./comp/Services/Australia/Service2"
import Service11 from "./comp/Services/Australia/Service3"
import Service12 from "./comp/Services/Australia/Service4"
import SubServiceDetail from './comp/SubServiceDetail'; 
import QualityAssurance from './comp/Services/Us/Sub-Services/QualityAssurance';
import FraudPrevention from './comp/Services/Us/Sub-Services/FraudPrevention';
import ForensicsSupport from './comp/Services/Us/Sub-Services/ForensicsSupport';
import Fractional from './comp/Services/Us/Sub-Services/Fractional';
function App() {  

  return (
    <div>
      <BrowserRouter>

      {/* <Home/> */}

      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/aboutus" element={<AboutUsInfo/>}/>
        <Route path="/contact" element={<ContactTab/>}/>
        <Route path="/cookie-policy" element={<CookiePage/>}/>
        <Route path="/us" element={<Us/>}/>
        <Route path="/canada" element={<Canada/>}/>
        <Route path="/Australia" element={<Australia/>}/>
        <Route path="/us/service1" element={<Service1/>}/>
        <Route path="/us/service2" element={<Service2/>}/>
        <Route path="/us/service3" element={<Service3/>}/>
        <Route path="/us/service4" element={<Service4/>}/>
        <Route path="/canada/service1" element={<Service5/>}/>
        <Route path="/canada/service2" element={<Service6/>}/>
        <Route path="/canada/service3" element={<Service7/>}/>
        <Route path="/canada/service4" element={<Service8/>}/>
        <Route path="/australia/service1" element={<Service9/>}/>
        <Route path="/autralia/service2" element={<Service10/>}/>
        <Route path="/australia/service3" element={<Service11/>}/>
        <Route path="/australia/service4" element={<Service12/>}/>


         {/* Sub-service pages under Assurance Support */}
        <Route path="/us/service1/quality-assurance" element={<QualityAssurance/>} />
        
        <Route path="/us/service1/fraud-prevention" element={<FraudPrevention />} />
        <Route path="/us/service1/forensics-support" element={<ForensicsSupport />} />
        <Route path="/us/service1/interpretation-gaap" element={<SubServiceDetail title="Interpretation of U.S GAAP, U.S GAAS and IFRS" />} />
        <Route path="/us/service1/financial-statements" element={<SubServiceDetail title="Preparation of Financial Statements" />} />
      
        {/* Sub-service pages under Tax Preparation and Review */}
        <Route path="/us/service2/business-corporate-taxes" element={<SubServiceDetail title="Business and Corporate Taxes" />} />
        <Route path="/us/service2/c-corps" element={<SubServiceDetail title="C-Corps" />} />
        <Route path="/us/service2/s-corps" element={<SubServiceDetail title="S-Corps" />} />
        <Route path="/us/service2/partnerships" element={<SubServiceDetail title="Partnerships" />} />


        {/* Sub-service pages under Business Services */}
       <Route path="/us/service3/fractional-cfo" element={<Fractional />} />
       <Route path="/us/service3/fractional-controller" element={<SubServiceDetail title="Fractional Controller Service" />} />
       <Route path="/us/service3/financial-modelling" element={<SubServiceDetail title="Financial Modelling" />} />
       <Route path="/us/service3/ai-readiness" element={<SubServiceDetail title="AI Readiness Assessment" />} />
       <Route path="/us/service3/valuation" element={<SubServiceDetail title="Valuation" />} />
       <Route path="/us/service3/quality-of-earnings" element={<SubServiceDetail title="Quality of Earnings (QoEs)" />} />
       <Route path="/us/service3/waterfall-computations" element={<SubServiceDetail title="Waterfall Computations" />} />
       <Route path="/us/service3/adr-solution" element={<SubServiceDetail title="Alternative Dispute Resolution (ADR) Solution" />} />
       <Route path="/us/service3/new-business-setup" element={<SubServiceDetail title="New Business Setup" />} />
       <Route path="/us/service3/startup-package" element={<SubServiceDetail title="Startup Package" />} />
       <Route path="/us/service3/general-accounting" element={<SubServiceDetail title="General Accounting" />} />


      </Routes>
      

      </BrowserRouter>
      <FloatingButtonForm />
    </div>
  );
}

export default App;