import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import "./CountriesNav.css";
import usIcon from "../../assets/us.png";
import canadaIcon from "../../assets/canada.webp";
import australiaIcon from "../../assets/Australia.png";

const CountriesNav = () => {
  const location = useLocation();

  return (
    <div className="countries" id="country-nav">
      <Link to="/us">
        <button
          className={`countries-btn ${location.pathname === '/us' ? 'active' : ''}`}
        >
          <img src={usIcon} alt="us-icon" />
          US
        </button>
      </Link>
      <Link to="/canada">
        <button
          className={`countries-btn ${location.pathname === '/canada' ? 'active' : ''}`}
        >
          <img src={canadaIcon} alt="canada-icon" />
          CANADA
        </button>
      </Link>
      <Link to="/australia">
        <button
          className={`countries-btn ${location.pathname === '/australia' ? 'active' : ''}`}
        >
          <img src={australiaIcon} alt="australia-icon" />
          AUSTRALIA
        </button>
      </Link>
    </div>
  );
};

export default CountriesNav;
